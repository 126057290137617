import React from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import DrawerPage from '../../components/app/DrawerPage';
import useZipCodeStore from '../../store/zipcode';
import { useLocation } from 'wouter';

const CountySection = ({
  county,
  selectedZipCodes,
  onToggleZipCode,
  onToggleAll,
}) => {
  const allZipCodesInCounty = county.zipCodes.map((zip) => zip.id);
  const selectedCount = county.zipCodes.filter((zip) =>
    selectedZipCodes.has(zip.id)
  ).length;
  const isAllSelected = selectedCount === county.zipCodes.length;

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`county-${county.id}-content`}
        id={`county-${county.id}-header`}
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          pr={2} // Add padding to prevent overlap with expand icon
        >
          <Typography variant="h6" style={{ color: '#2F455C' }}>
            {county.name}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginRight: 16 }}
            >
              {selectedCount} of {county.zipCodes.length} selected
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllSelected}
                  onChange={(e) => {
                    e.stopPropagation(); // Prevent accordion from toggling
                    onToggleAll(county.id, allZipCodesInCounty, !isAllSelected);
                  }}
                  onClick={(e) => e.stopPropagation()} // Prevent accordion from toggling
                  color="primary"
                />
              }
              label="Select All"
              onClick={(e) => e.stopPropagation()} // Prevent accordion from toggling
            />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          pl={2} // Add left padding for better visual hierarchy
        >
          {county.zipCodes.map((zipCode) => (
            <FormControlLabel
              key={zipCode.id}
              control={
                <Checkbox
                  checked={selectedZipCodes.has(zipCode.id)}
                  onChange={() => onToggleZipCode(zipCode.id)}
                  color="primary"
                />
              }
              label={zipCode.zip}
              style={{ marginBottom: 8 }} // Add spacing between zip codes
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const UpdateZipCodesPage = () => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [saveError, setSaveError] = React.useState(null);
  const [, setLocation] = useLocation();

  const {
    counties,
    isLoading,
    error,
    selectedZipCodes,
    fetchCounties,
    toggleZipCode,
    toggleAllCountyZipCodes,
    saveZipCodes,
  } = useZipCodeStore((state) => ({
    counties: state.counties,
    isLoading: state.isLoading,
    error: state.error,
    selectedZipCodes: state.selectedZipCodes,
    fetchCounties: state.fetchCounties,
    toggleZipCode: state.toggleZipCode,
    toggleAllCountyZipCodes: state.toggleAllCountyZipCodes,
    saveZipCodes: state.saveZipCodes,
  }));

  React.useEffect(() => {
    fetchCounties();
  }, [fetchCounties]);

  const handleSave = async () => {
    setIsSaving(true);
    setSaveError(null);

    try {
      const success = await saveZipCodes();
      if (success) {
        setLocation('/');
      }
    } catch (error) {
      setSaveError('Failed to save zip codes. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <DrawerPage px={12} py={6} width="100%">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="400px"
        >
          <CircularProgress />
        </Box>
      </DrawerPage>
    );
  }

  if (error) {
    return (
      <DrawerPage px={12} py={6} width="100%">
        <Alert severity="error">{error}</Alert>
      </DrawerPage>
    );
  }

  return (
    <DrawerPage px={12} py={6} width="100%">
      <Box width="100%">
        <Typography variant="h2" style={{ color: '#2F455C', marginBottom: 24 }}>
          Update Zip Codes
        </Typography>

        <Box mb={4}>
          {counties.map((county) => (
            <CountySection
              key={county.id}
              county={county}
              selectedZipCodes={selectedZipCodes}
              onToggleZipCode={toggleZipCode}
              onToggleAll={toggleAllCountyZipCodes}
            />
          ))}
        </Box>

        {saveError && (
          <Box mt={2} mb={4}>
            <Alert severity="error">{saveError}</Alert>
          </Box>
        )}

        <Box display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            style={{ color: '#fd6b6b', borderColor: '#fd6b6b' }}
            onClick={() => setLocation('/users/self')}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#49a3ba',
              color: 'white',
              '&:hover': {
                backgroundColor: '#3a8294',
              },
            }}
            onClick={handleSave}
            disabled={isSaving}
          >
            {isSaving ? <CircularProgress size={24} /> : 'Save Changes'}
          </Button>
        </Box>
      </Box>
    </DrawerPage>
  );
};

export default UpdateZipCodesPage;
